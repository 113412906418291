import { Button, Tooltip, IcFluentQrCode24Regular } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QrCodeModal from '../../Modals/QrCodeModal';

type Props = {
  handleQrScan: (result: string) => void;
  inModal?: boolean;
  validate: (arg0: string) => string | null;
};

const QrCodeReader = ({ handleQrScan, inModal, validate }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const showTooltip = !inModal;

  const onScan = (data: string | null) => {
    // Wait until we have a scan result
    if (!data) return;

    // Get an error from the validation prop and display it. Otherwise, handle the successful scan.
    const errorTemp = validate(data);
    if (errorTemp) {
      setError(errorTemp);
    } else {
      setError('');
      setShowModal(false);
      handleQrScan(data);
    }
  };

  const handleQrError = () => {
    setError(t('qrCodeReader.qrCodeIssue'));
  };

  return (
    <>
      <Tooltip show={showTooltip} label={t('qrCodeReader.qrCodeScanner')}>
        <Button
          theme="primary"
          aria-label={t('qrCodeReader.openScanner')}
          data-testid="qrCodeReader__button__openScanner"
          icon={<IcFluentQrCode24Regular />}
          onClick={() => setShowModal(true)}
          size="36"
        />
      </Tooltip>

      {showModal && (
        <QrCodeModal
          show={showModal}
          onHide={() => {
            setError('');
            setShowModal(false);
          }}
          onScan={onScan}
          handleQrError={handleQrError}
          error={error}
        />
      )}
    </>
  );
};

export default QrCodeReader;
