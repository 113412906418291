import { Modal, ErrorHandler } from '@flipgrid/flipkit';
import QrReader from 'react-qr-reader';

type Props = {
  error: string;
  handleQrError: () => void;
  onHide: () => void;
  onScan: (data: string | null) => void;
  show: boolean;
};

const QrCodeModal = ({ show, onHide, error, handleQrError, onScan }: Props) => {
  return (
    <Modal show={show} onClose={onHide}>
      <ErrorHandler error={error} id="qrCodeModalError" />
      <QrReader onError={handleQrError} onScan={onScan} />
    </Modal>
  );
};

export default QrCodeModal;
